export interface TokenResponse {
    data: {
        access_token: string;
        refresh_token: string;
        expires_in: number;
    };
}

class AuthService {
    private BASE_URL: string;
    private PLATFORM: string;

    constructor() {
        this.BASE_URL = process.env.REACT_APP_SSO_BASE_URL || '';
        this.PLATFORM = 'REVAMP_INTERNAL';
    }

    public getStoredTokens() {
        return {
            accessToken: localStorage.getItem('accessToken'),
            refreshToken: localStorage.getItem('refreshToken'),
        };
    }

    public async exchangeCodeForToken(code: string): Promise<TokenResponse['data']> {
        try {
            const response = await fetch(`${this.BASE_URL}/api/auth/token`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ code }),
            });

            if (!response.ok) {
                throw new Error('Token exchange failed');
            }

            const data = await response.json() as TokenResponse;

            localStorage.setItem('accessToken', data.data.access_token);
            localStorage.setItem('refreshToken', data.data.refresh_token);

            return data.data;
        } catch (error) {
            console.error('Code exchange failed:', error);
            throw error;
        }
    }

    public async refreshAccessToken(): Promise<TokenResponse['data']> {
        const refreshToken = localStorage.getItem('refreshToken');
        if (!refreshToken) {
            throw new Error('No refresh token available');
        }

        try {
            const response = await fetch(`${this.BASE_URL}/api/auth/refresh`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ refresh_token: refreshToken }),
            });

            if (!response.ok) {
                throw new Error('Token refresh failed');
            }

            const data = await response.json() as TokenResponse;

            localStorage.setItem('accessToken', data.data.access_token);

            return data.data;
        } catch (error) {
            console.error('Token refresh failed:', error);
            throw error;
        }
    }

    public getAuthorizeUrl(returnUrl: string = window.location.pathname): string {
        const currentOrigin = window.location.origin;
        const returnUrlObj = new URL(returnUrl, currentOrigin);
        returnUrlObj.searchParams.set('auth', 'callback');
        const callbackUrl = `${currentOrigin}${returnUrlObj.pathname}${returnUrlObj.search}`;
    
        return `${this.BASE_URL}/admin/authorize?platform=${this.PLATFORM}&redirect_uri=${encodeURIComponent(callbackUrl)}`;
    }

    public async logout(): Promise<void> {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');

        window.location.href = `${this.BASE_URL}/admin/logout`;
    }
}

export const authService = new AuthService();

import React, { createContext, useContext, useState, useEffect } from 'react';
import { authService, TokenResponse } from './AuthService';

export interface AuthContextType {
  loading: boolean;
  isAuthenticated: () => boolean;
  login: () => void;
  logout: () => Promise<void>;
  refreshAccessToken: () => Promise<TokenResponse['data']>;
}

export interface AuthProviderProps {
  children: React.ReactNode;
}

const AuthContext = createContext<AuthContextType | null>(null);

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const initializeAuth = async () => {
      try {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');
        const isAuthCallback = urlParams.get('auth') === 'callback';
        
        if (code && isAuthCallback) {
          await handleCallback(code);
        } 
      } catch (error) {
        console.error('Authentication initialization failed:', error);
      } finally {
        setLoading(false);
      }
    };

    initializeAuth();
  }, []);

  const handleCallback = async (code: string): Promise<void> => {
    try {
      setLoading(true);
      await authService.exchangeCodeForToken(code);
    } catch (error) {
      console.error('Authentication failed:', error);
      login();
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const isAuthenticated = (): boolean => {
    const { accessToken, refreshToken } = authService.getStoredTokens();
    return !!(accessToken && refreshToken);
  };

  const login = (): void => {
    const authorizeUrl = authService.getAuthorizeUrl();
    window.location.href = authorizeUrl;
  };

  const logout = async (): Promise<void> => {
    try {
      await authService.logout();
    } catch (error) {
      console.error('Logout failed:', error);
      throw error;
    }
  };

  const refreshAccessToken = async (): Promise<TokenResponse['data']> => {
    try {
      const tokens = await authService.refreshAccessToken();
      return tokens;
    } catch (error) {
      login();
      throw error;
    }
  };

  const value: AuthContextType = {
    loading,
    isAuthenticated,
    login,
    logout,
    refreshAccessToken,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

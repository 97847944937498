import { API_URL, API_URL_GO } from "constants/config";
import MainStore from "MainStore";
import { authService } from "AuthService";
import axios, { AxiosError, InternalAxiosRequestConfig } from "axios";

const httpGO = axios.create({
  baseURL: API_URL_GO
});

const http = axios.create({
  baseURL: API_URL,
});

const uanthenticatedHttpGo = axios.create({
  baseURL: API_URL_GO
});

const isAPIGO = true

const requestInterceptor = (httpInstance: typeof http | typeof httpGO) => {
  httpInstance.interceptors.request.use((config: InternalAxiosRequestConfig) => {
    const accessToken = authService.getStoredTokens().accessToken;
    config.headers.Authorization = `Bearer ${accessToken}`;
    return config;
  });
};

const responseInterceptor = (httpInstance: typeof http) => {
  httpInstance.interceptors.response.use(
    (response) => response as any,
    async (error: AxiosError) => {
      const originalRequest = error.config as InternalAxiosRequestConfig & { _retry?: boolean };

      if (!originalRequest) {
        return Promise.reject(error);
      }

      if (error.response?.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;

        try {
          const response = await authService.refreshAccessToken();
          const newToken = response.access_token

          if (!newToken) {
            throw new Error('No token received from refresh');
          }

          originalRequest.headers.Authorization = `Bearer ${newToken}`;

          return httpInstance(originalRequest);
        } catch (refreshError) {
          authService.logout();
          return Promise.reject(refreshError);
        }
      }

      if (error.response.status === 403) {
        MainStore.setOpenError403(true);
      }

      if (error.response.status === 422) {
        // @ts-ignore
        MainStore.setSnackbar(error.response.data.Content, "error");
      }

      return Promise.reject(error);
    }
  );
};

requestInterceptor(http);
requestInterceptor(httpGO);
responseInterceptor(http);
responseInterceptor(httpGO);

const postUnauthenticatedGO = (url: string, data: any, headers = {}, params = {}) => {
  return uanthenticatedHttpGo.post(url, data, {
    ...params,
    headers: {
      ...headers
    }
  });
};


const getGO = (url: string, headers = {}, params = {}) => {
  return httpGO.get(url, {
    ...params,
    headers: {
      ...headers,
    }
  });
};

const postGO = (url: string, data: any, headers = {}, params = {}) => {
  return httpGO.post(url, data, {
    ...params,
    headers: {
      ...headers
    }
  });
};

const removeGO = (url: string, data: any, headers = {}) => {
  return httpGO.delete(url, {
    headers: {
      ...headers,
    },
    data,
  });
};

const patchGO = (url: string, data: any, headers = {}) => {
  return httpGO.patch(url, data, {
    headers: {
      ...headers,
    },
  });
};

const putGO = (url: string, data: any, headers = {}) => {
  return httpGO.put(url, data, {
    headers: {
      ...headers,
    },
  });
};

const get = (url: string, headers = {}, params = {}) => {
  return http.get(url, {
    ...params,
    headers: {
      ...headers,
    },
  });
};

const post = (url: string, data: any, headers = {}, params = {}) => {
  return http.post(url, data, {
    ...params,
    headers: {
      ...headers,
    },
  });
};

const put = (url: string, data: any, headers = {}) => {
  return http.put(url, data, {
    headers: {
      ...headers,
    },
  });
};

const remove = (url: string, data: any, headers = {}) => {
  return http.delete(url, {
    headers: {
      ...headers,
    },
    data,
  });
};


const patch = (url: string, data: any, headers = {}) => {
  return http.patch(url, data, {
    headers: {
      ...headers,
    },
  });
};

const module = {
  isAPIGO,
  getGO,
  postUnauthenticatedGO,
  postGO,
  removeGO,
  patchGO,
  putGO,
  http,
  httpGO,
  get,
  post,
  put,
  remove,
  patch,
};

export default module;

import { FC } from "react";
import { LogOutIcon } from "./LogOutIcon";
import { INTERNAL_TOOL_MENU } from "../../__mocks__/menu";
import { AccountSettingsIcon } from "./AccountSettingsIcon";

import styled from "styled-components";
import { useAuth } from "AuthProvider";

const AppHeader: FC = () => {
  const { logout } = useAuth();

  return (
    <StyledHeader>
      <StyledMenuHeader>
        {INTERNAL_TOOL_MENU.map((menuItem, index) => (
          <ItemMenu
            id={`Navigate_Container_${index}`}
            key={menuItem.name}
            $active={window.location.href.includes(menuItem.path)}
          >
            <a id={`Navigate_Link_${index}`} href={menuItem.path}>{menuItem.name}</a>
          </ItemMenu>
        ))}
      </StyledMenuHeader>

      <RightContent>
        <StyledLink
        id={`Navigate_Settings`}
          href="/settings"
          $active={window.location.href.includes("/settings")}
        >
          <AccountSettingsIcon />
          Account settings
        </StyledLink>
        <StyledLogOutRightContent id={`SignOut`} onClick={() => logout()}>
          <LogOutIcon />
          Sign out
        </StyledLogOutRightContent>
      </RightContent>
    </StyledHeader>
  );
};

export default AppHeader;

const StyledHeader = styled.div`
  background-color: var(--colorNeutralBackground1);
  height: 80px;
  box-shadow: 0px 2px 20px 0px var(--darkGrayBoxShadow);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 48px;
`;

const StyledLogOutRightContent = styled.button`
  background-color: transparent;
  color: var(--colorNeutralForeground1);
  display: flex;
  align-items: center;
  cursor: pointer;
  border: none;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;

  svg {
    margin-right: 8px;
  }

  :hover {
    color: var(--colorBrandForeground1);
  }
`;

const RightContent = styled.div`
  display: flex;
  align-items: center;
  height: 80px;
`;

const StyledLink = styled.a<{ $active?: boolean }>`
  display: flex;
  align-items: center;
  margin-right: 20px;
  height: 100%;

  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  margin-right: 48px;
  color: ${(props) =>
    props.$active
      ? "var(--colorBrandForeground1)"
      : "var(--colorNeutralForeground1)"};

  svg {
    margin-right: 8px;
  }

  :hover {
    color: var(--colorBrandForeground1);
  }
`;

const StyledMenuHeader = styled.ul`
  width: fit-content;
  display: flex;
  height: 100%;
  height: 80px;
  margin: 0px;
  padding: 0px;
`;

const ItemMenu = styled.li<{ $active?: boolean }>`
  list-style-type: none;
  a {
    cursor: pointer;
    display: flex;
    align-items: center;

    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: ${(props) =>
      props.$active
        ? "var(--colorBrandForeground1)"
        : "var(--colorPaletteVioletBackground1)"};
    height: 100%;
    width: fit-content;
    padding: 0px 16px;
    border-bottom: ${(props) =>
      props.$active && `4px solid var(--colorBrandForeground1)`};
    height: 100%;
    padding-top: ${(props) => (props.$active ? "4px" : "0px")};
  }
`;
